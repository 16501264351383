.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /* background-image: url('../../assets/Laptop_above.jpeg'); */
  background-image: url('../../assets/Laptop.jpeg');
  background-size: cover;
  /* background-color: #C0C0C0; */
  /* background-color: darkgray; */
  /* background-color: grey; */
  /* background-color: #A9A9A9; */
  /* background-color: #808080; */
  /* background-color: #7393B3; */
  background-color: #36454F;
  /* background-blend-mode: multiply; */
  background-blend-mode: overlay;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 3vmin);
}

.App-link {
  color: #61dafb;
}
