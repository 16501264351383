.aboutmesection {
    padding: 1% 20%;
    background-color: #160227;
    text-align: left;
    color: white;
    font-size: 1.2rem;
}

/* Small devices (portrait tablets and large phones, 600px and less) */
@media only screen and (max-width: 600px) {
    .aboutmesection {
        padding: 1% 10%;
    }
}