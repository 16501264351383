.navbar {
    margin-right: 10%;
    color: white;
}

.container-fluid {
    justify-content: space-around;
}

.navbar-nav {
    align-items: flex-end;
}

button {
    border: none;
    background-color: #160227;
    /* font-family: cursive; */
    color: white;
    font-size: x-large;
    margin: 0 10px;
    padding: 6px 12px;
    border-radius: 8px;
}

button:hover {
    background-color: #3D3B8E;
}

.navbar-toggler .navbar-toggler-icon {
    /* color: white; */
    background-color: white;
}

/* Small devices (portrait tablets and large phones, 600px and less) */
@media only screen and (max-width: 600px) {
    .button {
       margin: 0;
    }
}