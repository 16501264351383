.contactcontainer {
    text-align: left;
    background-color: #160227;
    /* width: 100%; */
    margin: 0;
    padding: 0% 20%;
    border: none;
}

.contactsection {
    /* padding: 0% 20%; */
    background-color: #160227;
    text-align: left;
    /* color: white; */
    font-size: 1.2rem;
    display: inline-flex;
    flex-direction: column;
}

.contactcard {
    background-color: white;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    margin: 0;
    align-items: center;
    /* width: fit-content; */
    padding: 6px;
    margin: 20px 20px 0 20px;
    word-wrap: break-word;
}

h5 {
    margin: 0 5px;
}

a {
    margin-right: 15px;
}

/* Small devices (portrait tablets and large phones, 600px and less) */
@media only screen and (max-width: 600px) {
    .contactcontainer {
        padding: 1% 10%;
    }

    .contactcard {
        flex-direction: column;
        text-align: center;
    }
    img {
        margin: 0;
    }
}

