.welcome {
    background: none;
    padding: 20px;
    border: none;
  }

h1, h2 {
    color: #160227;
    color: #FFD6C0;
    text-shadow: 1px 1px #160227;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3rem;
}