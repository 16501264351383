.logoSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10%;
    color: white;
}

h3 {
    margin-bottom: 0;
    font-size: xx-large;
}

img {
    margin-right: 20px;
}