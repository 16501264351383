.projectssection {
    padding: 0% 20%;
    background-color: #160227;
    text-align: left;
    color: white;
    font-size: 1.2rem;
}

.card {
    margin: 20px;
}

.verticalsection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* Small devices (portrait tablets and large phones, 600px and less) */
@media only screen and (max-width: 600px) {
    .projectssection {
        padding: 1% 10%;
    }
}